<template>
  <div class="clue">
    <div class="container">
      <!-- 顶部卡片 -->
      <el-card class="top_card">
        <el-form ref="form" :model="queryInfo" label-width="120">
          <!-- <el-form-item label="线索状态：">
            <el-radio-group v-model="queryInfo.clueState" @change="changeSearchangeSearch">
              <el-radio-button :label="item.id" v-for="item in clueStateList" :key="item.id">
                {{ item.val }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item> -->

          <el-form-item label="线索类型：">
            <el-radio-group v-model="queryInfo.clueType" @change="changeSearchangeSearch">
              <el-radio-button :label="''" :key="''"> 全部 </el-radio-button>
              <el-radio-button :label="item.codeName" v-for="item in typeList" :key="item.id">
                {{ item.codeName }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="线索状态：">
            <el-radio-group v-model="queryInfo.clueStage" @change="changeSearchangeSearch">
              <el-radio-button :label="''" :key="''"> 全部 </el-radio-button>
              <el-radio-button :label="item.codeName" v-for="item in stageList" :key="item.id">
                {{ item.codeName }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="所在城市：">
            <city-select
              ref="child"
              :province="queryInfo.province"
              :city="queryInfo.city"
              :district="queryInfo.district"
              @searchProvince="searchProvince"
              @searchCity="searchCity"
              @searchDistrict="searchDistrict"
            />
          </el-form-item>

          <el-form-item label="线索来源：">
            <el-radio-group v-model="queryInfo.clueResource" @change="changeSearchangeSearch">
              <el-radio-button
                :label="item.codeName"
                v-for="item in clueSourcesList"
                :key="item.id"
              >
                {{ item.codeName }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>

          <!-- 底部 -->
          <el-form-item label=" " label-width="0" style="margin-bottom: 0">
            <div class="newClue">
              <el-button type="primary" @click="addClueBtn">新增线索</el-button>
              <!-- 右边搜索 -->
              <div class="search_right">
                <el-input
                  placeholder="请输入线索标题、企业名称"
                  v-model="queryInfo.keys"
                  class="input-with-select"
                  style="width: 500px"
                  clearable
                  @change="changeSearchangeSearch"
                >
                  <el-button slot="append" @click="changeSearchangeSearch">搜索</el-button>
                </el-input>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </el-card>
      <div style="display: flex; align-items: center; padding: 10px 0px">
        <div style="padding-right: 50px">
          <span>当前条件下共查出{{ total }}条线索</span>
        </div>
        <div style="display: flex; align-items: center; margin-right: 30px">
          按创建时间
          <div class="main">
            <span
              class="arrUp"
              @click="clickUp1"
              :style="{ 'border-bottom-color': orderinfo.color1 }"
            ></span>
            <span
              class="arrDown"
              @click="clickDown2"
              :style="{ 'border-top-color': orderinfo.color2 }"
            ></span>
          </div>
        </div>
        <div style="display: flex; align-items: center; margin-right: 30px">
          按等级
          <div class="main">
            <span
              class="arrUp"
              @click="clickUp3"
              :style="{ 'border-bottom-color': orderinfo.color3 }"
            ></span>
            <span
              class="arrDown"
              @click="clickDown4"
              :style="{ 'border-top-color': orderinfo.color4 }"
            ></span>
          </div>
        </div>
        <div style="display: flex; align-items: center; margin-right: 30px">
          按分数
          <div class="main">
            <span
              class="arrUp"
              @click="clickUp5"
              :style="{ 'border-bottom-color': orderinfo.color5 }"
            ></span>
            <span
              class="arrDown"
              @click="clickDown6"
              :style="{ 'border-top-color': orderinfo.color6 }"
            ></span>
          </div>
        </div>
      </div>
      <!-- 卡片列表 -->
      <div class="card_list" v-loading="loading">
        <div class="clue_card" v-for="(item, index) in datalist" :key="index">
          <div class="card_left">
            <!-- 企业图片区域 -->
            <div class="clue_img">
              <!-- <div class="img_left_top one" v-if="item.clueState == 1 || item.clueState == null"> -->
              <!-- <span>{{ clueStateList[1].val }}</span> -->
              <!-- </div> -->
              <div class="img_left_top two" v-if="item.clueState == '正常'">
                <span>正常</span>
              </div>
              <div class="img_left_top three" v-if="item.clueState == '冻结'">
                <span>冻结</span>
              </div>
              <div class="img_left_top four" v-if="item.clueState == '完成'">
                <span>完成</span>
              </div>
              <img :src="item.clueImage" alt="图片" />
            </div>
            <!-- 企业信息 -->
            <div class="card_info">
              <div class="card_title row">
                <div>
                  <span class="clue_name">{{ item.clueName }}</span>
                </div>
                <div class="type_culb" v-if="item.clueType">
                  {{ item.clueType }}
                </div>
                <div class="type_alert" v-if="item.businessOpportunity">
                  <el-alert :closable="false" title="该线索已被转化为商机" type="warning" show-icon>
                  </el-alert>
                </div>
              </div>
              <div style="height: 25px">
                <el-row class="row" v-if="item.clueTableList">
                  <el-tag
                    effect="plain"
                    v-for="(i1, i2) in item.clueTableList.slice(0, 2)"
                    :key="i2"
                    >{{ i1 }}</el-tag
                  >
                  <div @mouseenter="showTags(index)" @mouseleave="hideTags" class="show_tags">
                    <el-tag style="cursor: pointer; background: #fff"
                      >{{ item.clueTableList.length }}+</el-tag
                    >
                    <!-- 要展示的更多标签 -->
                    <div class="tags_box" v-show="isShowTags && tagIndex == index">
                      <el-tag effect="plain" v-for="(i1, i2) in item.clueTableList" :key="i2">{{
                        i1
                      }}</el-tag>
                    </div>
                  </div>
                </el-row>
              </div>

              <!-- <div style="display: flex" class="row">
                <div class="corp_name">{{ item.companyFullName }}</div>
                <div style="padding: 0 10px" class="clue_stage">
                  <span>{{ item.clueStage }}</span>
                </div>
              </div> -->
              <div style="display: flex" class="row">
                <div class="corp_name">{{ item.companyFullName }}</div>
                <div style="padding: 0 10px; display: flex" class="clue_stage">
                  <img
                    style="height: 16px; width: 16px"
                    src="../../assets/image/Frame (1).png"
                    v-if="item.clueStage == '采购前了解'"
                  />
                  <img
                    style="height: 16px; width: 16px"
                    src="../../assets/image/Frame (2).png"
                    v-else
                  />
                  <span style="margin-left: 5px">{{ item.clueStage }}</span>
                </div>
              </div>
              <div class="bottom_row">
                <span style="margin-right: 20px">
                  所在地区：{{ item.province }}-{{ item.city }}
                </span>
                <span>开始日期：{{ item.clueStartTime }}</span>
              </div>
            </div>

            <!-- 分数 -->
            <div class="souce">
              <div class="souce_item" v-if="item.clueEvaluationLevel">
                等级：<span>{{ item.clueEvaluationLevel }}</span>
              </div>
              <div class="souce_item" v-if="item.clueEvaluationScore">
                分数：<span>{{ item.clueEvaluationScore }}</span>
              </div>
            </div>
            <div class="division_line"></div>
          </div>
          <!-- 数据统计 -->
          <div class="count_list">
            <div class="col first_col" @click="serviceBtn(item)">
              <div class="row">{{ item.serviceNum || 0 }}</div>
              <div>
                <span>服务商</span>
              </div>
            </div>
            <div class="col" @click="peopleClick(item)">
              <div class="row">{{ item.specialistNum || 0 }}</div>
              <div>
                <span>专家</span>
              </div>
            </div>
            <div class="col" @click="clueLogClick(item)">
              <div class="row">{{ item.clueLogNum || 0 }}</div>
              <div>
                <span>线索日志</span>
              </div>
            </div>
            <!-- <div class="col first_col" @click="pushEnterpriseBtn(item)"> -->
            <!-- <div class="row">{{ item.cluePush || 0 }}</div> -->
            <!-- <div> -->
            <!-- <span>推送</span> -->
            <!-- </div> -->
            <!-- </div> -->
            <!-- <div class="col" @click="indifferentUsersBtn(item)"> -->
            <!-- <div class="row">{{ item.clueParticipation || 0 }}</div> -->
            <!-- <div> -->
            <!-- <span>参与</span> -->
            <!-- </div> -->
            <!-- </div> -->
            <!-- <div class="col" @click="checkUsersBtn(item)"> -->
            <!-- <div class="row">{{ item.clueCheck || 0 }}</div> -->
            <!-- <div> -->
            <!-- <span>查看</span> -->
            <!-- </div> -->
            <!-- </div> -->
            <!-- <div class="col" @click="shareUsersBtn(item)"> -->
            <!-- <div class="row">{{ item.clueShare || 0 }}</div> -->
            <!-- <div> -->
            <!-- <span>分享</span> -->
            <!-- </div> -->
            <!-- </div> -->
          </div>

          <!-- 右侧按钮 -->
          <div class="card_right">
            <div class="btn_list row" v-show="!item.businessOpportunity">
              <!-- <div> -->
              <!-- <el-button type="primary" style="color: #fff" @click="configEnterpriseBtn(item)" -->
              <!-- >配置企业</el-button -->
              <!-- > -->
              <!-- </div> -->
              <!-- <span class="click" @click="updateStage(item)" style="color: #4e93fb" -->
              <!-- >修改线索阶段</span -->
              <!-- > -->
              <!-- <span class="click" style="color: #4e93fb" @click="labelClick(item)">标签管理</span> -->
              <span class="click" style="color: #4e93fb" @click="goMatchingManagement(item)"
                >匹配管理</span
              >
              <span class="click" style="color: #4e93fb" @click="clueAssessment(item)"
                >线索评估</span
              >
              <el-popover placement="bottom" width="80" trigger="click">
                <div>
                  <div
                    class="popover_text"
                    style="margin-bottom: 10px"
                    @click="exportDocument(item)"
                  >
                    <div>需求文档</div>
                    <i class="el-icon-download"></i>
                  </div>
                  <div class="popover_text" @click="exportSelectionDocument(item)">
                    <div>甄选分析报告</div>
                    <i class="el-icon-download"></i>
                  </div>
                </div>

                <span slot="reference" class="click" style="color: #4e93fb">导出文档</span>
              </el-popover>
              <span class="click" style="color: #4e93fb" @click="changeBusiness(item)"
                >转化商机</span
              >
              <span @click="editClueBtn(item)" class="click" style="color: #4e93fb">编辑</span>

              <!-- <span v-if="item.notFreeze == 1" class="frozen" @click="delcompany(item)">冻结</span>
              <span v-if="item.notFreeze == 0" class="frozen" @click="delcompany(item)">激活</span> -->
            </div>
            <div style="position: relative; top: 30px" class="row">
              <span class="operate">操作人：{{ item.userName }}</span>
              <div>
                <span class="operate">最近操作时间：{{ item.updateTime }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 分页区域 -->
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[4, 10, 30]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog
      title="修改线索阶段"
      :visible.sync="dialogVisible"
      width="40%"
      @close="handleCloseMenu"
    >
      <el-form ref="form" :model="param" :rules="menuRules" label-width="130px" :inline="true">
        <el-form-item label="线索阶段" prop="clueStage">
          <el-radio-group v-model="param.clueStage">
            <el-radio v-for="item in codeType" :key="item.codeId" :label="item.codeName">{{
              item.codeName
            }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 转化商机 -->
    <el-dialog :visible.sync="convertBusinessDialog" width="20%" center>
      <div class="my-custom-class">是否转化商机</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="convertCancel">取 消</el-button>
        <el-button type="primary" @click="convertSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import CitySelect from '@/components/CitySelect.vue'
import {
  serverApiClueList,
  notFreeze,
  updateStage,
  listCompanyId,
  transformBusinessOpportunity,
  saveNumber,
  downloadPdf,
  downloadAnalysisPdf
} from '@/api/clue.js'
import { getCode } from '@/api/demand.js'
const defaultQueryInfo = Object.freeze({
  pageNum: 1,
  pageSize: 10,
  orders: 1, // 排序 默认传1 2->按创建时间升序;3->按创建时间降序;4->按押金升序;5->按押金降序;6->按线索费升序;7->按线索费降序8->按查看升序;9->按查看降序;10->按分享升序;11->按分享降序;12->按推送升序;13->按推送降序
  city: '', //城市
  province: '', //省份
  keys: '', //线索标题/企业名称
  clueState: '', //线索状态 1->邀约中;2->进行中;3->已完结;4->已终止
  clueStage: '', // 线索阶段
  clueType: '', //线索类型
  district: '', // 区县
  clueResource: '' // 线索来源
})
const defaultOrder = Object.freeze({
  color1: '',
  color2: '#C6C6C6',
  color3: '',
  color4: '#C6C6C6',
  color5: '',
  color6: '#C6C6C6',
  color7: '',
  color8: '#C6C6C6',
  color9: '',
  color10: '#C6C6C6',
  color11: '',
  color12: '#C6C6C6'
})

export default {
  components: { CitySelect },
  name: 'clue',
  data() {
    return {
      //查询参数
      queryInfo: { ...defaultQueryInfo },
      total: 0,
      //排序字段
      orderinfo: { ...defaultOrder },
      //线索卡片列表
      datalist: [],
      //线索类型
      typeList: [],
      //线索阶段
      stageList: [],
      //线索状态
      clueStateList: [
        {
          id: '',
          val: '全部'
        },
        {
          id: 1,
          val: '正常'
        },
        {
          id: 2,
          val: '冻结'
        },
        {
          id: 3,
          val: '完成'
        }
      ],
      menuRules: {
        clueStage: [{ required: true, message: '请选择线索阶段', trigger: 'change' }]
      },
      //控制是否显示更多标签
      isShowTags: false,
      dialogVisible: false, //修改线索阶段
      convertBusinessDialog: false, // 转换商机弹窗
      tagIndex: '',
      codeType: [],
      param: {
        clueSmartId: '', //线索编号
        clueStage: '' //线索阶段
      },
      loading: false,
      clueSourcesList: [], // 线索来源
      convertBusinessId: '' // 转换商机id
    }
  },
  created() {
    // 获取线索类型
    this.getCodeType('019')
    // 获取线索阶段
    this.getCodeState('020')
    this.getCodeState('033')
    // this.getCode()
    // this.search()
    if (this.$route.query.pageNum) {
      this.queryInfo.pageNum = Number(this.$route.query.pageNum)
      this.search()
    } else {
      this.queryInfo.pageNum = 1
      this.search()
    }
  },
  computed: {},
  methods: {
    // 转换商机
    async convertBusiness() {
      const { data: res } = await transformBusinessOpportunity({ clueId: this.convertBusinessId })
      if (res.resultCode === 200) {
        this.$message.success('已转化为商机')
        this.search()
      } else {
        this.$message.warnning('网络繁忙')
      }
    },
    // 点击转换商机
    changeBusiness(item) {
      this.convertBusinessId = item.id
      this.convertBusinessDialog = true
    },
    // 确定
    convertSave() {
      this.convertBusiness()
      this.convertBusinessDialog = false
    },
    // 取消
    convertCancel() {
      this.convertBusinessDialog = false
      this.convertBusinessId = ''
    },
    // 线索评估
    clueAssessment(row) {
      this.$router.push({
        name: 'clueAssessment',
        query: {
          id: row.id
        }
      })
    },
    // 获取数据
    async getCodeType(val) {
      const { data: res } = await getCode({ codeType: val })
      if (res.resultCode == 200) {
        this.typeList = res.data
      }
    },
    async getCodeState(val) {
      const { data: res } = await getCode({ codeType: val })
      if (res.resultCode == 200) {
        if (val == '020') {
          this.stageList = res.data
        }
        if (val == '033') {
          this.clueSourcesList = res.data
        }
      }
    },
    //请求数据
    async search() {
      this.loading = true
      const { data: res } = await serverApiClueList(this.queryInfo)
      if (res.resultCode === 200) {
        this.datalist = res.data.list
        this.total = res.data.total
      } else {
        this.$message.error('获取线索信息失败！')
      }
      this.loading = false
    },
    //新增线索按钮
    async addClueBtn() {
      const { data } = await saveNumber()
      if (data.resultCode == 200) {
        this.$router.push({
          name: 'combination',
          query: {
            headTabActive: 0,
            clueSmartNumber: data.data
          }
        })
      }
    },
    // 标签管理
    labelClick(row) {
      this.$router.push({
        name: 'combination',
        query: {
          clueId: row.id,
          headTabActive: 1
        }
      })
    },
    //编辑线索按钮
    editClueBtn(row) {
      this.$router.push({
        name: 'combination',
        query: {
          clueId: row.id,
          headTabActive: 0,
          pageNum: this.queryInfo.pageNum
        }
      })
    },
    //修改线索阶段
    updateStage(row) {
      this.param.clueSmartId = row.id
      this.param.clueStage = row.clueStage
      this.dialogVisible = true
    },
    // 导出线索需求文档
    async exportClueDemand(data) {
      const res = await downloadPdf({ clueId: data })
      if (res.data) {
        let blob = new Blob([res.data])
        let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
        const a = document.createElement('a') //创建a标签
        a.style.display = 'none'
        a.href = href // 指定下载'链接
        let nowdata = new Date()
        let year = nowdata.getFullYear()
        let month = nowdata.getMonth() + 1
        let date = nowdata.getDate()
        let nowtime = year + '-'
        if (month < 10) {
          nowtime += '0'
        }
        nowtime += month + '-'
        if (date < 10) {
          nowtime += '0'
        }
        nowtime += date
        a.download = `线索需求文档${nowtime}.docx` //指定下载文件名
        a.click() //触发下载
        window.URL.revokeObjectURL(a.href) //释放URL对象
        if (document.body.contains(a)) {
          document.body.removeChild(a) //释放标签
        }
      } else {
        this.$message.error('下载失败!')
      }
    },
    exportDocument(item) {
      this.exportClueDemand(item.id)
    },

    // 导出线索甄选文档
    async exportSelectionDemand(data) {
      const res = await downloadAnalysisPdf({ clueId: data })
      if (res.data) {
        let blob = new Blob([res.data])
        let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
        const a = document.createElement('a') //创建a标签
        a.style.display = 'none'
        a.href = href // 指定下载'链接
        let nowdata = new Date()
        let year = nowdata.getFullYear()
        let month = nowdata.getMonth() + 1
        let date = nowdata.getDate()
        let nowtime = year + '-'
        if (month < 10) {
          nowtime += '0'
        }
        nowtime += month + '-'
        if (date < 10) {
          nowtime += '0'
        }
        nowtime += date
        a.download = `甄选分析文档${nowtime}.docx` //指定下载文件名
        a.click() //触发下载
        window.URL.revokeObjectURL(a.href) //释放URL对象
        if (document.body.contains(a)) {
          document.body.removeChild(a) //释放标签
        }
      } else {
        this.$message.error('下载失败!')
      }
    },
    exportSelectionDocument(item) {
      this.exportSelectionDemand(item.id)
    },

    //取消弹窗
    handleCloseMenu() {
      this.dialogVisible = false
    },
    //确认线索阶段
    submitForm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          updateStage(this.param).then((response) => {
            if (response.data.resultCode == 200) {
              this.$message.success('修改成功')
              this.dialogVisible = false
              this.search()
            } else {
              this.$message.error(response.data.message)
            }
          })
        }
      })
    },
    //线索code状态
    // async getCode() {
    //   const { data: res } = await getCode({ codeType: '016' })
    //   if (res.resultCode == 200) {
    //     this.codeType = res.data
    //   }
    // },
    //冻结、
    delcompany(row) {
      const params = {
        id: row.id
      }

      this.$alert('您是否确认操作？')
        .then(function () {
          return notFreeze(params)
        })
        .then(() => {
          this.search()
          this.$message.success('操作成功')
        })
        .catch(() => {})
    },
    //返回省
    searchProvince(val) {
      this.queryInfo.pageNum = 1
      this.queryInfo.province = val
      this.search()
    },
    //返回城市
    searchCity(val) {
      this.queryInfo.city = val
      this.search()
    },
    searchDistrict(val) {
      this.queryInfo.district = val
      this.search()
    },
    //排序按创建时间
    clickUp1() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '1'
      this.search()
      this.orderinfo.color1 = '#0099ff'
      this.orderinfo.color2 = ''
    },
    clickDown2() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '2'
      this.search()
      this.orderinfo.color1 = ''
      this.orderinfo.color2 = '#0099ff'
    },
    //排序按等级
    clickUp3() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '3'
      this.search()
      this.orderinfo.color3 = '#0099ff'
      this.orderinfo.color4 = ''
    },
    clickDown4() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '4'
      this.search()
      this.orderinfo.color3 = ''
      this.orderinfo.color4 = '#0099ff'
    },
    //排序按线分数
    clickUp5() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '5'
      this.search()

      this.orderinfo.color5 = '#0099ff'
      this.orderinfo.color6 = ''
    },
    clickDown6() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '6'
      this.search()

      this.orderinfo.color5 = ''
      this.orderinfo.color6 = '#0099ff'
    },
    //排序按查看
    // clickUp7() {
    //   this.orderinfo = { ...defaultOrder }
    //   this.queryInfo.orders = '8'
    //   this.search()

    //   this.orderinfo.color7 = '#0099ff'
    //   this.orderinfo.color8 = ''
    // },
    // clickDown8() {
    //   this.orderinfo = { ...defaultOrder }
    //   this.queryInfo.orders = '9'
    //   this.search()

    //   this.orderinfo.color7 = ''
    //   this.orderinfo.color8 = '#0099ff'
    // },
    // //排序按分享
    // clickUp9() {
    //   this.orderinfo = { ...defaultOrder }
    //   this.queryInfo.orders = '10'
    //   this.search()

    //   this.orderinfo.color9 = '#0099ff'
    //   this.orderinfo.color10 = ''
    // },
    // clickDown10() {
    //   this.orderinfo = { ...defaultOrder }
    //   this.queryInfo.orders = '11'
    //   this.search()

    //   this.orderinfo.color9 = ''
    //   this.orderinfo.color10 = '#0099ff'
    // },
    // //排序按推送
    // clickUp11() {
    //   this.orderinfo = { ...defaultOrder }
    //   this.queryInfo.orders = '12'
    //   this.search()

    //   this.orderinfo.color11 = '#0099ff'
    //   this.orderinfo.color12 = ''
    // },
    // clickDown12() {
    //   this.orderinfo = { ...defaultOrder }
    //   this.queryInfo.orders = '13'
    //   this.search()

    //   this.orderinfo.color11 = ''
    //   this.orderinfo.color12 = '#0099ff'
    // },

    showTags(i) {
      this.tagIndex = i
      this.isShowTags = true
    },
    hideTags() {
      this.isShowTags = false
    },
    //配置企业按钮
    async configEnterpriseBtn(row) {
      //字段匹配企业
      this.loading = true
      const query = {
        clueTable: row.clueTableList ? row.clueTableList.toString() : '',
        smartId: row.id
      }
      const { data: res } = await listCompanyId(query)
      if (res.resultCode == 200) {
        this.loading = false
        let newtime = res.data
        this.$router.push({
          name: 'configEnterprise',
          query: {
            id: row.id,
            newtime: newtime
          }
        })
      } else {
        this.loading = false
        this.$message.error('匹配失败')
      }
    },
    //跳转到线索日志
    clueLogClick(row) {
      this.$router.push({
        name: 'clueLog',
        query: {
          id: row.id
        }
      })
    },
    //跳转到专家
    peopleClick(row) {
      this.$router.push({
        name: 'expertPeople',
        query: {
          id: row.id
        }
      })
    },
    // 跳转服务商
    serviceBtn(row) {
      this.$router.push({
        name: 'service',
        query: {
          id: row.id
        }
      })
    },
    //跳转到推送企业
    pushEnterpriseBtn(row) {
      this.$router.push({
        name: 'pushEnterprise',
        query: {
          id: row.id
        }
      })
    },
    //跳转查看用户
    checkUsersBtn(row) {
      this.$router.push({
        name: 'checkUsers',
        query: {
          id: row.id
        }
      })
    },
    //跳转分享用户
    shareUsersBtn(row) {
      this.$router.push({
        name: 'shareUsers',
        query: {
          id: row.id
        }
      })
    },
    //跳转参与
    indifferentUsersBtn(row) {
      this.$router.push({
        name: 'indifferentUsers',
        query: {
          id: row.id
        }
      })
    },
    // 跳转商机管理
    goMatchingManagement(row) {
      this.$router.push({
        name: 'matchingManagement',
        query: {
          clueId: row.id
        }
      })
    },
    //监听pagesize变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.search()
    },
    //监听页码变化
    handleCurrentChange(newCurrent) {
      this.queryInfo.pageNum = newCurrent
      this.search()
    },
    // 搜索
    changeSearchangeSearch() {
      this.queryInfo.pageNum = 1
      this.search()
    }
  }
}
</script>

<style lang="less" scoped>
.clue {
  overflow: hidden;
  .container {
    height: calc(100vh - 150px);
    overflow: auto;
    &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
  }
  ::v-deep .el-radio-button__inner {
    border: none;
    margin-right: 12px;
    border-radius: 4px;
  }
}
.newClue {
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
}
.search_right {
  width: 500px;
  margin-right: 40px;
  ::v-deep .el-input-group__append {
    border-right: 0;
  }
  ::v-deep .el-button {
    border: #448aff 1px solid;
    color: #fff;
    background-color: #448aff;
  }
}
.main {
  display: flex;
  flex-direction: column;
}

.arrUp {
  width: 0;
  height: 0;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  border-bottom: 8px solid #c6c6c6;
  cursor: pointer;
}

.arrDown {
  width: 0;
  height: 0;
  margin-top: 2px;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  border-top: 8px solid #c6c6c6;
  cursor: pointer;
}

.top_card {
  margin-bottom: 10px;
}
.card_list {
  width: 100%;
  min-height: 58vh;
}
.clue_card {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 20%;
  margin-bottom: 10px;
  background: #ffffff;
  padding: 20px 20px;
  box-sizing: border-box;
}

.card_left {
  display: flex;
  width: 60%;
  height: 100%;
  .clue_img {
    flex-shrink: 0;
    position: relative;
    width: 160px;
    height: 104px;
    margin-right: 10px;
    border-radius: 1px;
    img {
      width: 160px;
      height: 104px;
      border-radius: 8px;
    }
  }
  .img_left_top {
    position: absolute;
    width: 93px;
    height: 34px;
    color: #fff;
    text-align: center;
    line-height: 34px;
    border-radius: 10px 10px 10px 0;
    top: 0;
    left: 0;
    transform: translate(-10px, -10px);
  }
  .one {
    background: #ff7d18;
  }
  .two {
    background: #599afb;
  }
  .three {
    background: #999999;
  }
  .four {
    background: #2cca4f;
  }
  .card_info {
    flex: 1;
    .el-row {
      display: flex;
      flex-flow: row nowrap;
    }
    .show_tags {
      position: relative;
      width: 50px;
      .tags_box {
        display: flex;
        flex-flow: row wrap;
        justify-content: left;
        width: 400px;
        padding-left: 10px;
        background-color: rgba(241, 241, 241, 0.6);
        box-shadow: 0 2px 4px rgba(241, 241, 241, 0.25);
        position: absolute;
        left: 100%;
        top: 100%;
        z-index: 999 !important;
        .el-tag {
          height: 18px;
          margin: 10px 10px 0 0;
          padding: 0 5px;
          line-height: 16px;
          font-size: 12px;
        }
      }
    }
    .row {
      margin-bottom: 10px;
    }
    .clue_name {
      font-size: 18px;
      font-weight: bolder;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      min-width: 40px;
      max-width: 200px;
      -webkit-box-orient: vertical;
    }
    .el-tag {
      height: 18px;
      margin-right: 10px;
      padding: 0 5px;
      line-height: 16px;
      font-size: 12px;
    }
    .card_title {
      display: flex;
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
      }

      .type_culb {
        box-sizing: border-box;
        padding: 2px 6px;
        border-radius: 4px;
        background-color: #4e93fb;
        color: #fff;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
      .type_alert {
        .el-alert {
          padding: 0;
        }
        .el-alert--warning.is-light {
          color: #f56c6c;
          background-color: #fef0f0;
        }
      }
    }
    .corp_name {
      font-size: 14px;
      line-height: 20px;
      padding-right: 10px;
      border-right: #e8e8e8 1px solid;
    }
    .clue_stage {
      color: #4e93fb;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
    .bottom_row {
      display: flex;
      font-size: 14px;
      line-height: 20px;
    }
    .middle_row {
      display: flex;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 5px;
    }
    .clue_num {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #6e6e6e;
      font-size: 14px;
      margin-right: 10px;
      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }
  }

  .souce {
    width: 166px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    .souce_item {
      display: flex;
      align-items: center;
      color: #333333;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      margin-left: 20px;

      span {
        font-size: 24px;
        color: #4e93fb;
        font-weight: bold;
        line-height: 26px;
      }
    }
  }
  .division_line {
    height: 50px;
    margin: auto 30px;
    border: 1px solid #b9b9b93a;
  }
}

.count_list {
  display: flex;
  padding: 10px;
  .row {
    color: #078bff;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .col {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 20px;
  }
  :hover span {
    color: #4e93fb;
  }

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
}

.card_right {
  display: flex;
  flex-direction: column;
  width: 20%;
  .frozen {
    color: #fd5469;
    cursor: pointer;
  }
  .row {
    margin-bottom: 10px;
  }
  .btn_list {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-around;
    position: relative;
    top: 20px;
    margin-bottom: 30px;
    .click {
      cursor: pointer;
    }
  }
  .operate {
    display: flex;
    justify-content: right;
    font-size: 10px;
    color: #999;
    margin-bottom: 15px;
  }
}
.new_page {
  margin: 20px auto;
  width: 100%;
  text-align: center;
}
.popover_text {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  .el-icon-download {
    font-size: 20px;
  }
}
.my-custom-class {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 18px;
}
</style>
